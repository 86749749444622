import type { ItemType } from '../../../types/enums';
import { NodeViewVariant } from '../../../types/enums';
import inlineDefaultProps from './NodeViewInline/NodeViewInlineDefaultProps';
import cardDefaultProps from './NodeViewCard/NodeViewCardDefaultProps';
import type { NodeViewCommonProps } from './types';
import type { ItemViewTypeAndProps, ItemViewVariant } from '../../entities/types';

const variantToComponentAndDefaultPropsMap: Record<NodeViewVariant, NodeViewCommonProps> = {
  inline: inlineDefaultProps,
  card: cardDefaultProps
};

/**
 * Merge default props for variant with props supplied with the variant, and additional provided props.
 * @param variant
 * @param additionalProps
 */
function mergeVariantProps<PropsType>(
  variant: ItemViewTypeAndProps<ItemType.NODE, ItemViewVariant<ItemType.NODE>> = {
    type: NodeViewVariant.INLINE
  },
  additionalProps?: PropsType
): PropsType {
  const defaultProps = variantToComponentAndDefaultPropsMap[variant?.type];
  return { ...defaultProps, ...variant?.props, ...additionalProps };
}

export default mergeVariantProps;
