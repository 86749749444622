import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import { PagerPosition } from '@aurora/shared-client/components/common/Pager/enums';
import { LastActivityTimePrependText, ViewSize } from '../../../../types/enums';
import type { NodeViewInlineProps } from '../types';

const defaultViewProps: Partial<NodeViewInlineProps> = {
  useNodeAvatar: true,
  nodeIconSize: IconSize.PX_50,
  useNodeTitle: true,
  useNodeDescription: true,
  useNodeTopicsCount: false,
  useNodeMembersCount: false,
  useNodeTopicsCountIcon: false,
  useChildNodes: false,
  useNodeLink: true,
  useBoardCount: false,
  useCategoryName: false,
  viewSize: ViewSize.MD,
  useEntireItemAsLink: false,
  isNestedNode: false,
  useUnreadMessagesCount: false,
  useNodeLatestActivityTime: false,
  textKey: LastActivityTimePrependText.NONE,
  useTextDescription: true,
  useLockIcon: true,
  nodeIconType: 'avatar',
  nestedNodePager: { showNestedNodesPager: false, pagerPosition: PagerPosition.START },
  descriptionClampLines: 1,
  useNeutralLabel: false,
  childrenSorts: null,
  titleAs: 'h5'
};

export default defaultViewProps;
