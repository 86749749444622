import type { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { useContext } from 'react';
import WidgetContext from '@aurora/shared-client/components/context/WidgetContext';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { getQueryParamKeyForWidget } from '../helpers/quilt/WidgetHelper';
import SectionWidgetContext from '@aurora/shared-client/components/context/SectionWidgetContext';

interface QueryKeyAndValue {
  /**
   * The generated query key for the widget.
   */
  key: EndUserQueryParams;
  /**
   * The query param value.
   */
  value: string | null;
}

/**
 * Creates a unique query param key based on the containing Widget, as well as returns
 * the value for that query param key.
 *
 * @param suffix optional name to append to the query param key to make it more specific.
 * @param defaultValue the default value to return when no query param exists for the key.
 *
 * @author Adam Ayres
 */
export default function useQueryParamValueForWidget(
  suffix: string | null = null,
  defaultValue: string | null = null
): QueryKeyAndValue {
  const { router } = useEndUserRoutes();
  const widgetContext = useContext(WidgetContext);
  const { location } = useContext(SectionWidgetContext);
  const selectedWidgetId = getQueryParamKeyForWidget(widgetContext, location, suffix, '-');
  const key = selectedWidgetId as EndUserQueryParams;
  const value = router.getUnwrappedQueryParam(selectedWidgetId as EndUserQueryParams, defaultValue);
  return { key, value };
}
